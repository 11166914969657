import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Axios from "axios";
import { DateTime } from "luxon";
import Cookies from 'js-cookie';
import { Trans as Translate, useTranslation } from 'react-i18next';
import classNames from "classnames";
import { useDebouncedCallback } from "use-debounce/lib";
import useEventCallback from '../../hooks/useEventCallback';
import { strofeApi } from "../../api/strofeApi";
import { usersSelectors } from "../../store/usersSlice";

import Toast from "react-bootstrap/Toast";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from "react-bootstrap/Button";

import { LoopingAudio } from "../../utils/LoopingAudio";
import NavigationHeader from "../NavigationHeader";
import LibraryEqualizer from "./LibraryEqualizer";
import DeleteSongModal from "./DeleteSongModal";
import ShareTrackModal from "../SongPlayer/ShareTrackModal";
import LibraryPlayer from "./LibraryPlayer";
import DownloadTrackFlow from "../../modals/Download/DownloadTrackFlow";
import { MOODS, STYLES } from "../CreateSong/CreateSong";
import SubscribeModal from "../../modals/Download/SubscribeModal";
import RegistrationModal from "../../registration/RegistrationModal";
import HeroSale from "../../layout/HeroSale";
import FilterModal from "./FilterModal";

import PlayIconV2 from "../../icons/PlayIconV2";
import PauseIconV2 from "../../icons/PauseIconV2";
import InfiniteLoader from "../../layout/InfiniteLoader/InfiniteLoader";
import LibraryIcon from "../../icons/LibraryIcon";
import MoodStyleIcon from "../SongPlayer/MoodStyleIcon";
import TriangleUpIcon from "../../icons/TriangleUpIcon";
import DurationIcon from "../../icons/DurationIcon";
import StrofeCoinMono from "../../icons/coins/StrofeCoinMono";
import StrofeCoin from "../../icons/coins/StrofeCoin";

import './SongLibrary.scss';
import StarIconOutline from "../../icons/StarIconOutline";
import StarIcon from "../../icons/StarIcon";

const MOBILE_SORTS = [
  {
    phrase: 'Date modified (newest first)',
    sortDir: 'desc',
    sortBy: 'updated_at',
  },

  {
    phrase: 'Date modified (oldest first)',
    sortDir: 'asc',
    sortBy: 'updated_at',
  },

  {
    divider: true,
  },

  {
    phrase: 'Title (ascending)',
    sortDir: 'asc',
    sortBy: 'title',
  },

  {
    phrase: 'Title (descending)',
    sortDir: 'desc',
    sortBy: 'title',
  },

  {
    divider: true,
  },

  {
    phrase: 'Track duration (shortest first)',
    sortDir: 'asc',
    sortBy: 'duration',
  },

  {
    phrase: 'Track duration (longest first)',
    sortDir: 'desc',
    sortBy: 'duration',
  },

  {
    divider: true,
  },

  {
    phrase: 'Mood',
    sortDir: 'asc',
    sortBy: 'mood'
  },

  {
    phrase: 'Style',
    sortDir: 'asc',
    sortBy: 'style'
  },
]
const SORTING_COLUMNS = {
  updated_at: {
    phrase  : 'Modified',
    sortDir : 'desc',
  },

  title: {
    phrase  : 'Title',
    sortDir : 'asc',
  },

  mood: {
    phrase  : 'Mood',
    sortDir : 'asc',
  },

  style: {
    phrase  : 'Style',
    sortDir : 'asc',
  },

  duration: {
    sortDir : 'asc',
  },

  // 'favorite',
}

// TODO -> ROW_HEIGHT has a magic number for now, it can be calculated by rendering a dummy table first:
const ROW_HEIGHT = 44;
const FETCH_SONGS_MIN_PER_PAGE = 10;
const FETCH_SONGS_MAX_PER_PAGE = 30;

const TRACK_DOWNLOAD_STARTED = 1;
const TRACK_DOWNLOAD_PROGRESS = 50;

export default function SongLibrary() {

  const calculatePerPage = () => (
    Math.min(Math.max(Math.floor((window.innerHeight / ROW_HEIGHT) / 5) * 5, FETCH_SONGS_MIN_PER_PAGE), FETCH_SONGS_MAX_PER_PAGE)
  );
  
  const [songs, setSongs] = useState([]);
  const [generatedSongs, setGeneratedSongs] = useState({});
  const [loading, setLoading] = useState(false);
  const [playingSong, setPlayingSong] = useState(false);
  const [loadingSong, setLoadingSong] = useState(false);
  const [loadedSong, setLoadedSong] = useState(false);
  const [playingStatus, setPlayingStatus] = useState(null);
  const [trackProgress, setTrackProgress] = useState({});
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  const [showDeleteSongId, setShowDeleteSongId] = useState(null);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [sortBy, setSortBy] = useState(() => Cookies.get('strofe__library_sort_by') || 'updated_at');
  const [sortDirection, setSortDirection] = useState(() => Cookies.get('strofe__library_sort_dir') || 'desc');
  const [loadingSortChange, setLoadingSortChange] = useState(false);
  const [perPage, ] = useState(() => calculatePerPage());
  const [shareSong, setShareSong] = useState(null);
  const [showShareTrack, setShowShareTrack] = useState(false);
  const [highlightedSongId, setHighlightedSongId] = useState(null);
  const [songDuration, setSongDuration] = useState(null);
  const [fetchMoreSongs, setFetchMoreSongs] = useState(false);
  const [initialLoad, setInitialLoad] = useState('initialize');
  const [songDuplicated, setSongDuplicated] = useState(false);
  const [duplicatedSongCount, setDuplicatedSongCount] = useState(0);
  const [showDownloadTrack, setShowDownloadTrack] = useState(false);
  const [downloadSong, setDownloadSong] = useState(false);
  const [showOptin, setShowOptin] = useState(null);
  const [optinType, setOptinType] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState(null);
  const [quickFilter, setQuickFilter] = useState(null);
  const [showSubscribe, setShowSubscribe] = useState(false);

  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const abTests = useSelector(usersSelectors.getAbTests);
  
  const skeletonRowRef = useRef();
  const observerRef = useRef();
  const mobileLibraryRef = useRef();

  const { t, i18n } = useTranslation();

  const loadSongs = useEventCallback((resetSongs = false, fetchPage = page, fetchSort = sortBy, fetchDirection = sortDirection, fetchFilters = filters, resetInitialLoad) => {
    !resetSongs && setLoading(true);

    if (loading) {
      return;
    }

    const params = {
      page       : fetchPage,
      sort_by    : fetchSort,
      sort_order : fetchDirection,
      per_page   : perPage,
    }

    const urlParams = new URLSearchParams(params);

    // urlParams will add param: 'undefined' or 'null' in the string, so make sure
    // to only append if the filter exists:
    fetchFilters && Object.entries(fetchFilters).forEach(([key, value]) => {
      if (value !== undefined) {
        urlParams.append(key, value);
      }
    });

    strofeApi.get(`/songs/?${urlParams.toString()}`).then(response => {

      let newSongs = response.data;

      if (newSongs.length === perPage) {
        setLoadMore(true);
      }
      else {
        setLoadMore(false);
      }

      setPage(fetchPage + 1);
      setLoadingSortChange(false);
      debouncedSortChange.cancel();

      if (resetSongs) {
        setSongs([...newSongs]);
      }
      else {
        // TODO -> this filter only needs to run if a delete was called, it can be optimized:
        newSongs = newSongs.filter(newSong => !songs.find(song => song.id === newSong.id));
        setSongs([...songs, ...newSongs]);
      }

      const newGenerated = {};

      newSongs.forEach(song => {
        if (song.generated) {
          newGenerated[song.id] = true; 
        }
      });

      setGeneratedSongs({ ...generatedSongs, ...newGenerated });
      !resetSongs && setLoading(false);
      setFetchMoreSongs(true);
      setDuplicatedSongCount(0);

      if (initialLoad === 'initialize' || resetInitialLoad) {
        setInitialLoad('loaded');
      }
    });
  });

  useEffect(() => {
    loadSongs()
  }, [loadSongs]);

  useEffect(() => {
    if (fetchMoreSongs) {
      setFetchMoreSongs(false);
      const rect = skeletonRowRef.current?.getBoundingClientRect();

      if (rect && rect.top < window.innerHeight) {
        loadSongs();
      }
    }
  }, [loadSongs, fetchMoreSongs]);

  const deleteSong = async () => {
    const id = showDeleteSongId;
    const index = songs.findIndex(song => song.id === id);

    setShowDeleteSongId(null);

    await strofeApi.delete(`/songs/${id}`);

    // when a song is duplicated, the fetch is increased; and reset when more songs are fetched (IE: 10 get fetched, but only 7 added):
    const response = await strofeApi.get(`/songs/?page=${(page-1) * perPage + duplicatedSongCount}&sort_by=${sortBy}&sort_order=${sortDirection}&per_page=1`);
    const responseSongs = response.data || [];

    const newSongs = [...songs, ...responseSongs];
    newSongs.splice(index, 1);
    setSongs(newSongs);
    setShowDeleteToast(true);

    if (responseSongs.length && responseSongs[0].generated) {
      const newGenerated = { [responseSongs[0].id]: true };
      setGeneratedSongs({ ...generatedSongs, ...newGenerated });
    }

    if (playingSong?.id === id) {
      stopSong();
    }
  }

  const updateFavorite = async id => {
    const newSongs = [...songs];
    const song = newSongs.find(song => song.id === id);
    song.favorite = !song.favorite;
    setSongs(newSongs);

    debouncedUpdateFavoriteApi(id, song.favorite);
  }

  const debouncedUpdateFavoriteApi = useDebouncedCallback((id, favorite) => {
    strofeApi.put(`/songs/${id}`, { song: { favorite }});
  }, 300);

  // TODO -> memory might be leaking and not freed
  const stopSong = () => {
    setPlayingStatus(null);
    setPlayingSong(null);

    LoopingAudio.close();
  }

  const onSongEnded = () => {
    setPlayingStatus(null);
    // setPlaybackPosition(LoopingAudio.playbackPosition); TODO -> reset player
  }

  useEffect(() => {
    LoopingAudio.initialize({ simplePlayer: true, onSongEnded });

    return(() => {
      stopSong();
    });
  }, []);

  // TODO -> using index now:
  const toggleSong = async id => {

    if (trackProgress[id]) {
      return;
    }

    if (playingSong?.id === id) {
      if (playingStatus === null) {
        LoopingAudio.playTrack();
        setPlayingStatus('playing');
      }
      else if (playingStatus === 'playing') {
        LoopingAudio.stopSong();
        setPlayingStatus('paused');
      }
      else if (playingStatus === 'paused') {
        LoopingAudio.resumeSong();
        setPlayingStatus('playing');
      }

      return;
    }

    setLoadingSong(id);
    setTrackProgress({ ...trackProgress, [id]: TRACK_DOWNLOAD_STARTED });

    const updateTrackProgress = () => {
      // setTrackProgress(trackProgress => ({ ...trackProgress, [id]: Math.min(trackProgress[id] + (trackProgress[id] > 70 ? 1 : 2), 98) }));
      setTrackProgress(trackProgress => ({ ...trackProgress, [id]: TRACK_DOWNLOAD_PROGRESS }));
    }
    
    const progressInterval = setInterval(updateTrackProgress, 300);  

    if (!generatedSongs[id]) {
      setGeneratedSongs({ ...generatedSongs, [id]: 'generating' });

      await generateSong(id);
    }

    const song = songs.find(song => song.id === id);
    await Axios.get(song.mp3_download_url);

    setTrackProgress(trackProgress => ({ ...trackProgress, [id]: undefined }));
    setLoadedSong(id);
    clearInterval(progressInterval);
  }

  const loadSong = useCallback(song => {

    const asyncLoad = async song => {
      await LoopingAudio.initialize({ simplePlayer: true, onSongEnded });
  
      // setting simple data (not real songData from /fetch song/id), to avoid generating of duplicated tracks.
      // /get of a duplicated track will generate the MP3s for each channel:
      LoopingAudio.setSongData(song);
      setPlayingSong(song);

      const duration = await LoopingAudio.loadSong(song.mp3_download_url);
      setSongDuration(duration);
      setPlayingStatus('playing');
  
      LoopingAudio.playTrack();
    }
    
    asyncLoad(song);
  }, []);

  useEffect(() => {
    if (loadedSong && loadedSong === loadingSong) {
      setLoadingSong(false);
      setLoadedSong(false);
      loadSong(songs.find(s => s.id === loadedSong));
    }
  }, [loadSong, loadedSong, loadingSong, songs])

  useEffect(() => {
    if (initialLoad === 'loaded') {
      setInitialLoad(false);

      const callback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            loadSongs();
          }
        });
      }
  
      // if intersector already exists, disconnect it first:
      observerRef.current?.disconnect();
      
      // the skeleton <tr> rows will not exist if on first load all user songs are loaded:
      if (skeletonRowRef.current) {
        observerRef.current = new IntersectionObserver(callback);
        observerRef.current.observe(skeletonRowRef.current);
      }
    }
  }, [initialLoad, loadSongs]);

  useEffect(() => {
    return () => {
      observerRef.current?.disconnect();
    }
  }, []);

  const updateSort = id => {
    let dir = SORTING_COLUMNS[id].sortDir;

    if (id === sortBy) {
      dir = sortDirection === 'asc' ? 'desc' : 'asc'
      setSortDirection(dir);
    }
    else {
      setSortBy(id);
      setSortDirection(SORTING_COLUMNS[id].sortDir);
    }

    Cookies.set('strofe__library_sort_by', id, { expires: 3650 });
    Cookies.set('strofe__library_sort_dir', dir, { expires: 3650 });

    loadSongs(true, 1, id, dir);
    debouncedSortChange(true);
  }

  const updateMobileSort = (sortBy, sortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);

    Cookies.set('strofe__library_sort_by', sortBy, { expires: 3650 });
    Cookies.set('strofe__library_sort_dir', sortDirection, { expires: 3650 });

    loadSongs(true, 1, sortBy, sortDirection);
    debouncedSortChange(true);
  }

  const debouncedSortChange = useDebouncedCallback(value => {
    setLoadingSortChange(value);
  }, 200);

  const sortableColumn = id => {

    let icon = null;

    if (id === 'duration') {
      icon = <DurationIcon className='title' />;
    }

    const phrase = SORTING_COLUMNS[id].phrase;

    return (
      <th className={classNames({ [`column-${id}`]: true })} role='button' onClick={() => updateSort(id)} tabIndex={0}>
        <div className='sortable'>
          { icon || <span className='title'><Translate>{ phrase }</Translate></span> }
          <TriangleUpIcon className={classNames('sort-icon', {desc: sortDirection === 'desc', visible: sortBy === id})} />
        </div>
      </th>
    )
  }

  const handleClickFavorite = (e, id) => {
    updateFavorite(id);

    // on mobiles, avoid propagating click to avoid toggling (pause/play) the track:
    if (isMobileView()) {
      e.stopPropagation();
    }
  }

  const handleClickPurchase = (e, song) => {
    handleDownload(song);

    if (isMobileView()) {
      e.stopPropagation();
    }
  }

  const songFavorite = song => (
    <button className='favorite' onClick={e => handleClickFavorite(e, song.id)} onDoubleClick={e => e.stopPropagation()}>
      { song.favorite
      ? <StarIcon className='active' />
      : <StarIconOutline className='inactive' /> 
      }
    </button>
  );

  const songPurchase = song => (
    <button className={classNames({purchased: song.purchased, purchase: !song.purchased })} onClick={e => handleClickPurchase(e, song)} onDoubleClick={e => e.stopPropagation()}>
      { song.purchased ? <StrofeCoin /> : <StrofeCoinMono /> }
    </button>
  );

  const handleShareSong = song => {
    setShareSong(song);
    
    if (currentUser.registered) {
      setShowShareTrack(true);
    }
    else {
      setOptinType('share');
      setShowOptin(true);
    }
  }

  const setAsPublic = id => {
    strofeApi.put(`/songs/${id}`, { song: { public: true }});
    const newSongs = [...songs];
    const index = newSongs.findIndex(song => song.id === id);
    newSongs[index].public = true;
    setSongs(newSongs);
  }

  const duplicateSong = async song => {
    const response = await strofeApi.post(`songs/duplicate/${song.id}`, { song: { title: t('trackname-copy', { trackname: song.title }) }});
    const duplicateSong = response.data;
    const songIndex = songs.findIndex(s => s.id === song.id);
    const newSongs = [...songs];
    newSongs.splice(songIndex + 1, 0, JSON.parse(JSON.stringify(duplicateSong)));
    setSongs(newSongs);
    setHighlightedSongId(duplicateSong.id);
    setSongDuplicated(true);
    setDuplicatedSongCount(duplicatedSongCount + 1);
  }

  const handleFilterSongs = (newFilters, resetQuickFilters) => {

    if (newFilters !== null) {
      const { purchased, favorite, mood, style } = newFilters;
    
      if (purchased === undefined && favorite === undefined && mood === undefined && style === undefined) {
        newFilters = null;
      }
    }
    else {
      setQuickFilter(null);
    }

    if (resetQuickFilters) {
      setQuickFilter(null);
    }

    setFilters(newFilters);
    setPage(1);
    setShowFilter(false);
    debouncedSortChange(true);

    loadSongs(true, 1, sortBy, sortDirection, newFilters, true);
  }

  const handleToggleQuickFilter = key => {
    const newFilters = { [key]: quickFilter === key ? undefined : true };
    setQuickFilter(quickFilter === key ? null : key);

    handleFilterSongs(newFilters);
  }

  const renderFilterMessage = () => {
    let message = 'Showing filtered results. ';

    if (quickFilter === 'favorite') {
      message = 'Showing favorite tracks only. ';
    }

    else if (quickFilter === 'purchased') {
      message = 'Showing purchased tracks only. ';
    }

    return (
      <Translate>
        { message }
        <span role='button' onClick={() => handleFilterSongs(null)}>Remove filter.</span>
      </Translate>
    );
  }

  const renderSkeletonRows = () => {
    const rows = [1,2,3];

    return rows.map(row => (
      <tr key={row} className={classNames('skeleton-row', `skeleton-row-${row}`)} ref={row === 1 ? skeletonRowRef : null}>
        {/*   #   */}<td className='mobile-hidden' />
        {/* Mood  */}<td className='centered'><div className='skeleton-cell skeleton-cell-1' style={{ width: 32, height: 32 }} /></td>
        {/* Title */}<td><div className='skeleton-cell' style={{ width: 128, height: 24 }} /></td>
        {/* Style */}<td className='mobile-hidden centered'><div className='skeleton-cell skeleton-cell-2' style={{ width: 64, height: 24 }} /></td>
        {/* Date  */}<td className='mobile-hidden centered'><div className='skeleton-cell skeleton-cell-3' style={{ width: 48, height: 24 }} /></td>
        {/* Time  */}<td className='mobile-hidden centered'><div className='skeleton-cell skeleton-cell-4' style={{ width: 32, height: 24 }} /></td>
        {/* Fave  */}<td className='desktop-hidden centered'><div className='skeleton-cell skeleton-cell-4' style={{ width: 32, height: 32 }} /></td>
        {/*  ...  */}
      </tr>
    ));
  }

  const isMobileView = () => (
    window.getComputedStyle(mobileLibraryRef.current).display === 'none'
  );

  // when clicking on a track row, play the track if it's the mobile view (set on css media with display = none)
  const clickTrackRow = (e, id) => {
    setHighlightedSongId(id);
    
    // bootstrap uses a <button> for its dropdown (...) and <a> for the items:
    if (isMobileView() && e.target?.tagName !== 'BUTTON' && e.target?.tagName !== 'A') {
      toggleSong(id);
    }
  }

  const songMetaData = song => (
    process.env.REACT_APP_LIBRARY_SHOW_TRACK_METADATA &&
    <>
      { generatedSongs[song.id] === true && ' - Gen' }
      { song.public === true && ' - Public' }
      { ` - id: ${song.id}` }
    </>
  );

  const handleDownload = song => {
    if (song.purchased) {
      showDownloadModal(song);
    }
    else if (currentUser.can_subscribe && !currentUser.subscribed && !currentUser.ambassador) {
      if (currentUser.registered) {
        setShowSubscribe(true);
      }
      else {
        setOptinType('subscribe');
        setShowOptin(true);
      }
    }
    else if (currentUser.registered) {
      showDownloadModal(song);
    }
    else {
      setShowOptin(true);
      setOptinType('download');
    }
  }

  const showDownloadModal = async song => {
    setDownloadSong(song);
    setShowDownloadTrack(true);

    if (song.purchased || process.env.REACT_APP_HIDE_PAYWALL === 'true') {
      const newSongs = [...songs];
      newSongs[newSongs.findIndex(s => s.id === song.id)].generated = false;
      setDownloadSong(newSongs.find(s => s.id === song.id));
    }
  }

  const hideDownloadTrack = () => {
    setShowDownloadTrack(false);
  }

  const generateSong = async id => {
    const res = await strofeApi.post(`/songs/generate/${id}`);
    setGeneratedSongs(generatedSongs => ({ ...generatedSongs, [id]: 'generated' }));

    return res;
  }

  const onPurchase = async (id, format) => {
    let newSongs = [...songs];
    const song = newSongs.find(s => s.id === id);

    song.purchased = true;

    if (format) {
      song[`${format}_purchased`] = true;
    }

    setSongs(newSongs);
    setDownloadSong(song);
  }

  const closeOptinModal = () => {
    setShowOptin(false);
  }

  const onOptinUserCreated = () => {
    switch (optinType) {
      case 'share':
        setShowShareTrack(true);
        break;

      case 'download':
        showDownloadModal(downloadSong);
        break;

      default:
        break;
    }
  }

  return (
    <div className='__song-library'>
      <NavigationHeader />
      <HeroSale />
      <div className='__song-library-container'>
        <div className='header'>
          <div className='title'>
            <LibraryIcon width={36} height={36} /><Translate>Library</Translate>
          </div>
          
          { (songs.length || !loading) && (
            <>
              <div className='sort-filter desktop-filter'>
                <Button className='favorites' variant='dark' onClick={() => handleToggleQuickFilter('favorite')} active={quickFilter === 'favorite'}>
                  <StarIcon width={16} height={16} />
                  <Translate>Favorites</Translate>
                </Button>

                <Button className='purchased' variant='dark' onClick={() => handleToggleQuickFilter('purchased')} active={quickFilter === 'purchased'}>
                  <StrofeCoin width={20} height={20} />
                  <Translate>Purchased</Translate>
                </Button>

                <Button className='filter' variant='dark' onClick={() => setShowFilter(true)} active={filters !== null && !quickFilter}>
                  <Translate>Custom Filter</Translate>
                </Button>
              </div>
            
              <div className='sort-filter mobile-filter'>
                <Button className='filter' variant='dark' onClick={() => setShowFilter(true)}>
                  <Translate>Filter</Translate>
                </Button>

                <Dropdown className='sort'>
                  <Dropdown.Toggle variant='dark'>
                    Sort
                  </Dropdown.Toggle>
                
                  <Dropdown.Menu align='right'>
                    { MOBILE_SORTS.map((sort, index) => (
                      sort.divider
                      ? <Dropdown.Divider key={`divider-${index}`} />
                      : <Dropdown.Item key={sort.phrase} active={sort.sortBy === sortBy && sort.sortDir === sortDirection} onClick={() => updateMobileSort(sort.sortBy, sort.sortDir)}><Translate>{ sort.phrase }</Translate></Dropdown.Item>
                    ))}                
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>
          )}
          
          </div>

        { filters !== null && (
          <div className='showing-filters'>
            { renderFilterMessage() }
          </div>
        )}

        { !songs.length && loading && (
          <div className='loading'>
            <InfiniteLoader width={48} />
            <pre><Translate>Loading Tracks...</Translate></pre>
          </div>
        )}

        {/* Used to detect if the mobile library is visible, so that clicking on tracks auto plays them (instead of just highlight): */}
        <div className='mobile-library' ref={mobileLibraryRef} />

        { songs.length > 0 && (
          <table className='track-view'>
            <thead>
              <tr>
                <th className='column-playback'>#</th>
                { sortableColumn('mood') }
                { sortableColumn('title') }
                { sortableColumn('style') }
                { sortableColumn('updated_at') }
                { sortableColumn('duration') }
                <th className='column-favorite' />
                { process.env.REACT_APP_HIDE_PAYWALL !== 'true' && <th className='column-purchased' /> }
                <th className='column-options' />
              </tr>
            </thead>

            <tbody>
              { songs?.map((song, index) => (
              <tr key={song.id} onClick={e => clickTrackRow(e, song.id)} onDoubleClick={() => toggleSong(song.id)}>
                <td className='centered cell-playback'>
                  <div className='playback-container'>
                    <div className={classNames('track-number', {'track-active': (playingSong?.id === song.id && playingStatus === 'playing') || trackProgress[song.id] })}>{ index + 1 }</div>
                    <div className={classNames('equalizer')}>{ playingSong?.id === song.id && playingStatus === 'playing' && <LibraryEqualizer /> }</div>
                    <div className='controls'>
                      { trackProgress[song.id] === TRACK_DOWNLOAD_PROGRESS
                      ? <div className='generate-progress'>
                          <InfiniteLoader />
                        </div>
                      : <div className={classNames('control-icons', { 'song-active': playingSong?.id === song.id && playingStatus === 'playing' })} role="button" onClick={() => toggleSong(song.id)}>
                          { playingSong?.id === song.id && playingStatus === 'playing' ? <PauseIconV2 /> : <PlayIconV2 className='play-icon' /> }
                        </div>
                      }
                    </div>                
                  </div>
                </td>
                <td className='centered cell-mood'>
                  { trackProgress[song.id] === TRACK_DOWNLOAD_PROGRESS && <InfiniteLoader className='generate-mobile' /> }
                  <MoodStyleIcon className={classNames({ 'regenerating-mobile': trackProgress[song.id] === TRACK_DOWNLOAD_PROGRESS })} mood={song.mood} width={32} height={32} />
                </td>
                <td>
                  <div className='title-container'>
                    <div className={classNames('title', {active: playingSong?.id === song.id})}>{ song.title }</div>
                    <div className='subtitle desktop'>
                      <Translate>{ MOODS.find(m => m.id === song.mood).phrase }</Translate>
                      { songMetaData(song) }
                    </div>
                    <div className='subtitle mobile'>
                      <Translate>{ MOODS.find(m => m.id === song.mood).phrase }</Translate>
                      {' / '}
                      <Translate>{ STYLES.find(e => e.id === song.style).phrase }</Translate>
                      { songMetaData(song) }
                    </div>
                  </div>
                </td>
                <td className='centered cell-style'><MoodStyleIcon genre={song.style} width={32} height={32} /></td>
                <td className='centered cell-modified'>{ DateTime.fromISO(song.updated_at).setLocale(i18n.language).toLocaleString() }</td>
                <td className='centered cell-updated_at'>{ LoopingAudio.secsToClockFormat(song.duration) }</td>
                <td className='centered cell-favorite'>{ songFavorite(song) }</td>
                { process.env.REACT_APP_HIDE_PAYWALL !== 'true' && <td className='centered cell-purchased'>{ songPurchase(song) }</td> }
                <td className='centered cell-options'>
                  <Dropdown className={classNames('options-menu', { highlighted: highlightedSongId === song.id })}>
                    <Dropdown.Toggle variant='link' onDoubleClick={e => e.stopPropagation()} disabled={loading}>
                    ···
                    </Dropdown.Toggle>
                    <Dropdown.Menu align='right'>
                      <Dropdown.Item as={Link} to={`/song/${song.id}`}><Translate>Edit Track</Translate></Dropdown.Item>
                      <Dropdown.Item onClick={() => handleDownload(song)}><Translate>Download</Translate></Dropdown.Item>
                      <Dropdown.Item onClick={() => handleShareSong(song)}><Translate>Share</Translate></Dropdown.Item>
                      <Dropdown.Item onClick={() => duplicateSong(song)}><Translate>Duplicate</Translate></Dropdown.Item>
                      <Dropdown.Item onClick={() => setShowDeleteSongId(song.id)}><Translate>Delete</Translate></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <div className={classNames('column-hover', { highlighted: highlightedSongId === song.id})} />
              </tr>
              ))}

              { loadMore && renderSkeletonRows() }

            </tbody>

          </table>
        )}
        
        { !loading && songs && !songs?.length && (
          <div className='empty-library'>
            <Translate>{ filters ? "There are no tracks that match the current filter." : "Your library is empty." }</Translate>
          </div>
        )}

        <LibraryPlayer song={playingSong} playingStatus={playingStatus} togglePlayback={() => toggleSong(playingSong.id)} songDuration={songDuration} setPlayingStatus={setPlayingStatus} />

        <DeleteSongModal show={showDeleteSongId !== null} onClose={() => setShowDeleteSongId(null)} onDelete={deleteSong} />
        <Toast show={showDeleteToast} className='strofe-toast' autohide delay={2000} onClose={() => setShowDeleteToast(false)}>
          <Toast.Body><Translate>Music track deleted</Translate></Toast.Body>
        </Toast>

        <Toast show={loadingSortChange} className='strofe-toast'>
          <Toast.Body><Translate>Loading...</Translate></Toast.Body>
        </Toast>

        <Toast show={songDuplicated} className='strofe-toast' autohide delay={1000} onClose={() => setSongDuplicated(false)}>
          <Toast.Body><Translate>Track duplicated</Translate></Toast.Body>
        </Toast>
        
        <RegistrationModal show={showOptin} onClose={closeOptinModal} from={optinType} onUserCreated={onOptinUserCreated} />
        <ShareTrackModal show={showShareTrack} onClose={() => setShowShareTrack(false)} onMakePublic={() => setAsPublic(shareSong?.id)} isPublic={shareSong?.public} songId={shareSong?.id} />
        <DownloadTrackFlow show={showDownloadTrack} song={downloadSong} onHide={hideDownloadTrack} onPurchase={onPurchase} />
        <FilterModal show={showFilter} onHide={() => setShowFilter(false)} onSubmit={handleFilterSongs} filters={filters} />
        <SubscribeModal show={showSubscribe} onHide={() => setShowSubscribe(false)} hasTrial={false} />
      </div>
    </div>
  )
}
